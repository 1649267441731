var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "活动名称" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "give_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "商品名称" },
                model: {
                  value: _vm.form.give_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "give_name", $$v)
                  },
                  expression: "form.give_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      _vm.form.pageSize = 10
                      _vm.form.pageNo = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleAdd } }, [
                _vm._v("创建搭赠活动"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleCheck($index, row)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    row.status_text == "进行中" ||
                    row.status_text == "未开始" ||
                    (row.status_text == "已结束" && row.is_closed == 0)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleEdit($index, row)
                              },
                            },
                          },
                          [_vm._v(" 修改 ")]
                        )
                      : _vm._e(),
                    row.status_text == "未开始" || row.status_text == "进行中"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleStop($index, row)
                              },
                            },
                          },
                          [_vm._v(" 停止 ")]
                        )
                      : _vm._e(),
                    row.status_text == "已停止"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handlefiring($index, row)
                              },
                            },
                          },
                          [_vm._v(" 启动 ")]
                        )
                      : _vm._e(),
                    row.status_text != "未开始" && row.status_text != "进行中"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDelete($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("check", { ref: "check", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
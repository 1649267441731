var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "800px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                disabled: !_vm.view,
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动名称:", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "活动名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "起止日期:", prop: "time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "限定业务员:", prop: "user" } },
                [
                  _c("span", { staticStyle: { "margin-right": "15px" } }, [
                    _vm._v(_vm._s(_vm.form.userlist.length) + " 人"),
                  ]),
                  _vm.view
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.setUser },
                        },
                        [_vm._v(" 设置 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "活动说明:", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { stripe: "", data: _vm.list1 },
                },
                [
                  _vm._l(_vm.finallyColumns1, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                item.label == "活动主品"
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          attrs: {
                                            "f-key": row.goods_name,
                                            search:
                                              _vm.view == 0 ? false : true,
                                          },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows(
                                                $event,
                                                1,
                                                $index
                                              )
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row,
                                                1
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "单位"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "单位",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.goods_unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "goods_unit_id",
                                                  $$v
                                                )
                                              },
                                              expression: "row.goods_unit_id",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_unit,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.unit_id,
                                                  label: i.unit_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "活动价"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          on: {
                                            input: function (v) {
                                              return _vm.price2wei(
                                                v,
                                                row,
                                                "goods_price"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.goods_price,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_price", $$v)
                                            },
                                            expression: "row.goods_price",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "数量"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number", min: 0 },
                                          model: {
                                            value: row.goods_count,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_count", $$v)
                                            },
                                            expression: "row.goods_count",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row[item.prop]) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm.view
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "",
                          align: "center",
                          label: "操作",
                          "min-width": "120px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleCopyRow(
                                            $index,
                                            row,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDeleteRow(
                                            $index,
                                            row,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1737023143
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.is_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_price", $$v)
                        },
                        expression: "form.is_price",
                      },
                    },
                    [
                      _vm._v(
                        " 主品价格按价格体系（即不使用统一活动价，按平时门店销售价） "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "80px" },
                  attrs: { label: "", prop: "is_change_goods" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "inactive-text": "主品等值替换:",
                    },
                    model: {
                      value: _vm.form.is_change_goods,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_change_goods", $$v)
                      },
                      expression: "form.is_change_goods",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主品等值金额:", prop: "main_amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.main_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "main_amount", $$v)
                      },
                      expression: "form.main_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: { stripe: "", data: _vm.list2 },
                },
                [
                  _vm._l(_vm.finallyColumns2, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                item.label == "搭赠商品"
                                  ? _c(
                                      "div",
                                      [
                                        _c("goods-search", {
                                          attrs: {
                                            "f-key": row.goods_name,
                                            search:
                                              _vm.view == 0 ? false : true,
                                          },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows(
                                                $event,
                                                2,
                                                $index
                                              )
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.selectGoods(
                                                $event,
                                                row,
                                                2
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "单位"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "单位",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange(
                                                  $event,
                                                  row
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.goods_unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "goods_unit_id",
                                                  $$v
                                                )
                                              },
                                              expression: "row.goods_unit_id",
                                            },
                                          },
                                          _vm._l(
                                            row.arr_unit,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.unit_id,
                                                  label: i.unit_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "数量"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: row.goods_count,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_count", $$v)
                                            },
                                            expression: "row.goods_count",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row[item.prop]) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm.view
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "",
                          align: "center",
                          label: "操作",
                          "min-width": "120px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleCopyRow(
                                            $index,
                                            row,
                                            2
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDeleteRow(
                                            $index,
                                            row,
                                            2
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4045850631
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-right": "80px" },
                  attrs: { label: "", prop: "is_change_gift" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "inactive-text": "赠品等值替换:",
                    },
                    model: {
                      value: _vm.form.is_change_gift,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_change_gift", $$v)
                      },
                      expression: "form.is_change_gift",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赠品等值金额:", prop: "gift_amount" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.gift_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gift_amount", $$v)
                      },
                      expression: "form.gift_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%", "margin-bottom": "5px" },
                  attrs: { label: "", prop: "is_gift_more" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.is_gift_more,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_gift_more", $$v)
                        },
                        expression: "form.is_gift_more",
                      },
                    },
                    [_vm._v(" 允许超额更换赠品 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%", "margin-bottom": "5px" },
                  attrs: { label: "", prop: "is_diff_gift" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.is_diff_gift,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_diff_gift", $$v)
                        },
                        expression: "form.is_diff_gift",
                      },
                    },
                    [_vm._v(" 更换赠品提示异常 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%", "margin-bottom": "5px" },
                  attrs: { label: "", prop: "is_diff_giftmore" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.is_diff_giftmore,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_diff_giftmore", $$v)
                        },
                        expression: "form.is_diff_giftmore",
                      },
                    },
                    [_vm._v(" 超额更换赠品提示异常 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.view
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
      _c("set-component", {
        ref: "setComponent",
        attrs: { "is-create": true },
        on: { "r-table-data": _vm.setUserId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request'

// 后台搭赠列表
export function getList(data) {
  return request({
    url: '/promoteAdmin/give-plan/index',
    method: 'post',
    data,
  })
}

// 删除搭赠
export function deleteRow(data) {
  return request({
    url: '/promoteAdmin/give-plan/delete',
    method: 'post',
    data,
  })
}

// 搭赠详情
export function giveDetail(data) {
  return request({
    url: '/promoteAdmin/give-plan/detail',
    method: 'post',
    data,
  })
}

// 新建搭赠
export function addGive(data) {
  return request({
    url: '/promoteAdmin/give-plan/create',
    method: 'post',
    data,
  })
}

// 修改搭赠
export function editGive(data) {
  return request({
    url: '/promoteAdmin/give-plan/update',
    method: 'post',
    data,
  })
}

// 改变搭赠状态
export function stopGive(data) {
  return request({
    url: '/promoteAdmin/give-plan/status',
    method: 'post',
    data,
  })
}

/**
 * /promoteAdmin/give-plan/form
搭赠统计
 */

export function summaryList(data) {
  return request({
    url: '/promoteAdmin/give-plan/form',
    method: 'post',
    data,
  })
}

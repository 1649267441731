<template>
  <!-- NAME[epic=动销] 搭赠活动 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="title">
        <el-input v-model="form.title" placeholder="活动名称"></el-input>
      </el-form-item>
      <el-form-item label="" prop="give_name">
        <!-- <goods-search
          ref="goodsSearch"
          @select-goods="selectGoods"
        ></goods-search> -->
        <el-input v-model="form.give_name" placeholder="商品名称"></el-input>
      </el-form-item>
      <el-form-item label="" prop="status">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleAdd">创建搭赠活动</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <!-- v-if="row.status_text == '未开始' || row.status_text == '进行中'" -->
          <el-button
            v-if="
              row.status_text == '进行中' ||
              row.status_text == '未开始' ||
              (row.status_text == '已结束' && row.is_closed == 0)
            "
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            修改
          </el-button>
          <el-button
            v-if="row.status_text == '未开始' || row.status_text == '进行中'"
            type="text"
            @click.native.prevent="handleStop($index, row)"
          >
            停止
          </el-button>
          <el-button
            v-if="row.status_text == '已停止'"
            type="text"
            @click.native.prevent="handlefiring($index, row)"
          >
            启动
          </el-button>
          <el-button
            v-if="row.status_text != '未开始' && row.status_text != '进行中'"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <check ref="check" @refresh="fetchData"></check>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList, deleteRow, stopGive } from '@/api/give'
  import Check from './components/check'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'GiveActive',
    components: { Check },
    data() {
      return {
        loading: false,
        // 状态1进行中2已结束3未开始4已停止
        statusList: [
          {
            id: 1,
            name: '进行中',
          },
          {
            id: 2,
            name: '已结束',
          },
          {
            id: 3,
            name: '未开始',
          },
          {
            id: 4,
            name: '已停止',
          },
        ],
        form: {
          pageNo: 1, //页数,默认第一页
          pageSize: 10, //条数,默认10条
          title: '', //活动名称
          give_name: '', //商品名称
          // goods_id: '',
          status: '', //状态1进行中2已结束3未开始4已停止
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        checkList: ['活动ID', '活动名称', '开始时间', '结束时间', '状态'],
        columns: [
          {
            order: 1,
            label: '活动ID',
            prop: 'id',
            width: '',
          },
          {
            order: 2,
            label: '活动名称',
            prop: 'title',
            width: '',
          },
          {
            order: 3,
            label: '开始时间',
            prop: 'begin_time',
            width: '',
          },
          {
            order: 4,
            label: '结束时间',
            prop: 'end_time',
            width: '',
          },
          {
            order: 5,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getList(this.form)
        this.total = Number(totalCount)
        this.list = data
        this.loading = false
      },
      handlefiring(index, row) {
        postAction('/promoteAdmin/give-plan/status', { id: row.id }).then(
          (res) => {
            this.$message.success(res.msg)
            this.fetchData()
          }
        )
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      selectGoods(val) {
        if (val) {
          this.form.goods_id = val
        } else {
          this.form.goods_id = ''
        }
      },
      handleAdd() {
        this.$refs.check.view = 1
        this.$refs.check.title = '创建搭赠活动'
        this.$refs.check.showDialog = true
      },
      // 表格操作
      handleCheck(index, row) {
        this.$refs.check.view = 0
        this.$refs.check.id = row.id
        this.$refs.check.title = '查看'
        this.$refs.check.showDialog = true
      },
      handleEdit(index, row) {
        this.$refs.check.view = 2
        this.$refs.check.id = row.id
        this.$refs.check.title = '修改'
        this.$refs.check.showDialog = true
      },
      handleStop(index, row) {
        stopGive({ id: row.id }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      handleDelete(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({ id: row.id }).then((res) => {
              this.$message.success(res.msg)
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>

<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="800px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        :disabled="!view"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="活动名称:" prop="title">
          <el-input v-model="form.title" placeholder="活动名称"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="起止日期:" prop="time">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 300px"
          ></el-date-picker>
        </el-form-item>
        <div></div>
        <el-form-item label="限定业务员:" prop="user">
          <span style="margin-right: 15px">{{ form.userlist.length }} 人</span>
          <el-button v-if="view" type="primary" @click="setUser">
            设置
          </el-button>
        </el-form-item>
        <div></div>
        <el-form-item label="活动说明:" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <!-- 表格1 -->
        <el-table stripe :data="list1" style="margin-bottom: 15px">
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns1"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row, $index }">
              <div v-if="item.label == '活动主品'">
                <goods-search
                  :f-key="row.goods_name"
                  :search="view == 0 ? false : true"
                  @add-rows="addRows($event, 1, $index)"
                  @select-goods-all="selectGoods($event, row, 1)"
                ></goods-search>
              </div>

              <div v-else-if="item.label == '单位'">
                <el-select
                  v-model="row.goods_unit_id"
                  clearable
                  placeholder="单位"
                  @change="unitChange($event, row)"
                >
                  <el-option
                    v-for="(i, idx) in row.arr_unit"
                    :key="idx"
                    :value="i.unit_id"
                    :label="i.unit_name"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="item.label == '活动价'">
                <el-input
                  v-model="row.goods_price"
                  @input="(v) => price2wei(v, row, 'goods_price')"
                ></el-input>
              </div>
              <div v-else-if="item.label == '数量'">
                <el-input
                  v-model="row.goods_count"
                  type="number"
                  :min="0"
                ></el-input>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="view"
            prop=""
            align="center"
            label="操作"
            min-width="120px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow($index, row, 1)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row, 1)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="" prop="">
          <el-checkbox v-model="form.is_price" :true-label="1" :false-label="0">
            主品价格按价格体系（即不使用统一活动价，按平时门店销售价）
          </el-checkbox>
        </el-form-item>
        <div></div>
        <el-form-item
          label=""
          prop="is_change_goods"
          style="margin-right: 80px"
        >
          <el-switch
            v-model="form.is_change_goods"
            :active-value="1"
            :inactive-value="0"
            inactive-text="主品等值替换:"
          ></el-switch>
        </el-form-item>
        <el-form-item label="主品等值金额:" prop="main_amount">
          <el-input
            v-model="form.main_amount"
            type="number"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <!-- 表格2 -->
        <el-table stripe :data="list2" style="margin-bottom: 15px">
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns2"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row, $index }">
              <div v-if="item.label == '搭赠商品'">
                <goods-search
                  :f-key="row.goods_name"
                  :search="view == 0 ? false : true"
                  @add-rows="addRows($event, 2, $index)"
                  @select-goods-all="selectGoods($event, row, 2)"
                ></goods-search>
              </div>
              <div v-else-if="item.label == '单位'">
                <el-select
                  v-model="row.goods_unit_id"
                  clearable
                  placeholder="单位"
                  @change="unitChange($event, row)"
                >
                  <el-option
                    v-for="(i, idx) in row.arr_unit"
                    :key="idx"
                    :value="i.unit_id"
                    :label="i.unit_name"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="item.label == '数量'">
                <el-input v-model="row.goods_count" type="number"></el-input>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="view"
            prop=""
            align="center"
            label="操作"
            min-width="120px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow($index, row, 2)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row, 2)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="" prop="is_change_gift" style="margin-right: 80px">
          <el-switch
            v-model="form.is_change_gift"
            :active-value="1"
            :inactive-value="0"
            inactive-text="赠品等值替换:"
          ></el-switch>
        </el-form-item>
        <el-form-item label="赠品等值金额:" prop="gift_amount">
          <el-input
            v-model="form.gift_amount"
            type="number"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label=""
          prop="is_gift_more"
          style="width: 100%; margin-bottom: 5px"
        >
          <el-checkbox
            v-model="form.is_gift_more"
            :true-label="1"
            :false-label="0"
          >
            允许超额更换赠品
          </el-checkbox>
        </el-form-item>
        <el-form-item
          label=""
          prop="is_diff_gift"
          style="width: 100%; margin-bottom: 5px"
        >
          <el-checkbox
            v-model="form.is_diff_gift"
            :true-label="1"
            :false-label="0"
          >
            更换赠品提示异常
          </el-checkbox>
        </el-form-item>
        <el-form-item
          label=""
          prop="is_diff_giftmore"
          style="width: 100%; margin-bottom: 5px"
        >
          <el-checkbox
            v-model="form.is_diff_giftmore"
            :true-label="1"
            :false-label="0"
          >
            超额更换赠品提示异常
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="view" type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
    <set-component
      ref="setComponent"
      :is-create="true"
      @r-table-data="setUserId"
    ></set-component>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import { giveDetail, addGive, editGive } from '@/api/give'
  import price2wei_ from '@/utils/priceNumberSize'
  export default {
    name: '',
    components: { GoodsSearch, SetComponent },
    data() {
      return {
        id: '',
        view: 0,
        loading: false,
        showDialog: false,
        title: '标题',
        time: [],
        user: '0',
        form: {
          id: '', // '搭赠id',
          title: '', // '活动名称',
          begin_time: '', // '开始时间',
          end_time: '', // '结束时间',
          remark: '', // '备注',
          is_change_gift: 0, // '可更换赠品1是0否',
          is_change_goods: 0, // '可更换主品1是0否',
          is_gift_more: 0, // '允许超额更换赠品1是0否',
          is_diff_gift: 0, // '更换赠品提示异常1提示0不提示',
          is_diff_giftmore: 0, // '更换超额赠品提示异常1提示0不提示',
          main_amount: '', // '主品金额',
          gift_amount: '', // '赠品金额',
          userlist: [],
        },
        list1: [],
        list2: [],
        checkNew: false,
        checkList1: ['活动主品', '规格', '活动价', '单位', '数量'],
        checkList2: ['搭赠商品', '规格', '单价', '单位', '数量'],
        columns1: [
          {
            order: 1,
            label: '活动主品',
            prop: 'goods_id',
            width: '200px',
          },
          {
            order: 2,
            label: '规格',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 2.5,
            label: '活动价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 3,
            label: '单位',
            prop: 'goods_unit_id',
            width: '',
          },
          {
            order: 4,
            label: '数量',
            prop: 'goods_count',
            width: '',
          },
        ],
        columns2: [
          {
            order: 1,
            label: '搭赠商品',
            prop: 'goods_id',
            width: '200px',
          },
          {
            order: 2,
            label: '规格',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 2.5,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 3,
            label: '单位',
            prop: 'goods_unit_id',
            width: '',
          },
          {
            order: 4,
            label: '数量',
            prop: 'goods_count',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns1() {
        let finallyArray = []
        this.checkList1.forEach((checkItem) => {
          finallyArray.push(
            this.columns1.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
      finallyColumns2() {
        let finallyArray = []
        this.checkList2.forEach((checkItem) => {
          finallyArray.push(
            this.columns2.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      // 0查看  1添加  2修改
      showDialog(val) {
        console.log(this.view)
        if (val) {
          if (this.view == 0) {
            this.fetchData()
          } else if (this.view == 1) {
            console.log('添加')
            this.addRow(1)
            this.addRow(2)
          } else if (this.view == 2) {
            console.log('修改')
            this.fetchData().then((e) => {
              this.addRow(1)
              this.addRow(2)
            })
          }
        } else {
          this.clearForm()
        }
      },
      time(val) {
        if (val) {
          this.form.begin_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.begin_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await giveDetail({ id: this.id })
        console.log(data)
        this.list1 = data.goodlist
        this.list2 = data.givelist
        this.time = [data.begin_time, data.end_time]
        console.log()
        this.form = data
        delete this.form.goodlist
        delete this.form.givelist
        this.loading = false
      },
      setUser() {
        this.$refs.setComponent.showDialog = true
        this.$refs.setComponent.rTableData = JSON.parse(
          JSON.stringify(this.form.userlist)
        )
      },
      setUserId(val) {
        console.log(val)
        this.form.userlist = JSON.parse(JSON.stringify(val))
      },
      obj() {
        return {
          goods_id: '',
          goods_unit_id: '',
          goods_name: '',
          goods_count: '',
          goods_unit_name: '',
          goods_specs: '',
          arr_unit: [],
          brand_id: '',
          brand_name: '',
          goods_price: '',
          goods_unit_name: '',
        }
      },
      // 表格操作
      handleCopyRow(index, row, listNum = 1) {
        let list = eval('this.list' + listNum)
        list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleDeleteRow(index, row, listNum) {
        let list = eval('this.list' + listNum)
        if (row.goods_id == '') {
          this.$message.error('不能删除此行')
          return false
        }
        if (list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          list.splice(index, 1)
        }
      },
      handleSave() {
        let form = JSON.parse(JSON.stringify(this.form))
        let goodlist = JSON.parse(JSON.stringify(this.list1))
        let givelist = JSON.parse(JSON.stringify(this.list2))
        // form.user_id = form.userlist.join(',')
        let userId = []
        form.userlist.forEach((i) => {
          userId.push(i.id)
        })
        form.user_id = userId.join(',')
        goodlist.forEach((item, index) => {
          if (item.goods_id == '') {
            goodlist.splice(index, 1)
          }
        })
        givelist.forEach((item, index) => {
          if (item.goods_id == '') {
            givelist.splice(index, 1)
          }
        })
        console.log(form, goodlist, givelist)
        let end = Object.assign(
          form,
          { goodlist: JSON.stringify(goodlist) },
          { givelist: JSON.stringify(givelist) }
        )
        console.log(end)
        if (this.view == 1) {
          console.log('新建')

          addGive(end).then((res) => {
            this.$message.success(res.msg)
            this.$emit('refresh')
            this.close()
          })
        } else if (this.view == 2) {
          console.log('修改')
          editGive(end).then((res) => {
            this.$message.success(res.msg)
            this.$emit('refresh')
            this.close()
          })
        } else {
          return false
        }
      },
      close() {
        this.showDialog = false
      },
      addRow(index) {
        console.log('addRow')
        eval('this.list' + index + '.push(this.obj())')
      },
      addRows(val, listNum, index) {
        let list = eval('this.list' + listNum)
        console.log(val)
        val.forEach((item) => {
          let a = {
            goods_id: item.goods_id,
            goods_name: item.goods_name,
            goods_specs: item.specs,
            arr_unit: item.arr_unit,
            goods_unit_id: item.unit_id,
            goods_count: item.quantity,
            brand_id: item.brand_id,
            brand_name: item.brand_name,
            goods_price: '',
            goods_unit_name: '',
            unit_id: item.unit_id,
          }
          console.log(
            '123',
            item.arr_unit.filter((i) => i.unit_id == a.unit_id)
          )
          a.goods_price = item.arr_unit.filter(
            (i) => i.unit_id == a.unit_id
          )[0].sell_price
          console.log(a)
          // list.push(a)
          list.splice(index, 0, a)
          // list.splice()
        })
      },
      unitChange(val, row) {
        console.log(val)
        console.log(row)
        row.goods_unit_name = row.arr_unit.filter(
          (item) => item.unit_id == val
        )[0].unit_name
        let price = row.arr_unit.filter((item) => item.unit_id == val)[0]
        let endP = ''
        console.log(price)
        if (price.sell_price == 0) {
          endP = price.purc_pirce
        } else {
          endP = price.sell_price
        }
        row.goods_price = endP
      },
      selectGoods(val, row, listNum = 1) {
        console.log(val)
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
        row.goods_specs = val.specs
        row.arr_unit = val.arr_unit
        this.$set(row, 'goods_unit_id', val.unit_id)
        row.goods_count = val.quantity
        row.brand_id = val.brand_id
        row.brand_name = val.brand_name
        row.goods_price = val.goods_price
        row.goods_unit_name = ''
        if (
          eval(
            'this.list' +
              listNum +
              '[this.list' +
              listNum +
              ".length-1].goods_id!=''"
          )
        ) {
          this.addRow(listNum)
        }
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.id = ''
        this.list1 = []
        this.list2 = []
        this.time = []
        this.form = {
          id: '', // '搭赠id',
          title: '', // '活动名称',
          begin_time: '', // '开始时间',
          end_time: '', // '结束时间',
          remark: '', // '备注',
          is_change_gift: 0, // '可更换赠品1是0否',
          is_change_goods: 0, // '可更换主品1是0否',
          is_gift_more: 0, // '允许超额更换赠品1是0否',
          is_diff_gift: 0, // '更换赠品提示异常1提示0不提示',
          is_diff_giftmore: 0, // '更换超额赠品提示异常1提示0不提示',
          main_amount: '', // '主品金额',
          gift_amount: '', // '赠品金额',
          userlist: [],
        }
      },
      price2wei(v, row, name) {
        price2wei_(v, row, name)
      },
    },
  }
</script>
<style lang="scss" scoped></style>

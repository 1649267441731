const price2wei = (v, row, priceName) => {
  const n = Number(v)
  const s = v.split('.')
  if (s.length > 2) {
    row[priceName] = s[0] + '.' + s[1]
  } else {
    if (n || n == 0) {
      if (s[3]) {
      } else if (s[1] && s[1].length > 2) {
        let e = s[0] + '.' + s[1].slice(0, 2)
        row[priceName] = e
      } else {
        row[priceName] = v
        return v
      }
    } else {
      if (parseInt(v)) {
        row[priceName] = parseFloat(v)
      } else {
        row[priceName] = ''
      }
    }
  }
}

export default price2wei
